import {
  SettingsCompanyDetailsDataDto,
  SettingsCompanyDetailsLegalEntityDto,
} from '../../dtos';
import {
  SettingsCompanyDetailsData,
  SettingsCompanyDetailsModel,
} from '../../models';

export class SettingsCompanyDetailsMapperService {
  static mapToSettingsCompanyDetailsModel(
    data: SettingsCompanyDetailsDataDto,
  ): SettingsCompanyDetailsModel {
    return {
      isUserAdmin: data.isAdmin,
      legalEntityList: this.getSettingsCompanyDetailsLegalEntityList(
        data?.legalEntities,
      ),
      parentCompany: this.getSettingsCompanyDetailsParentCompany(
        data?.parentCompany,
      ),
    };
  }

  private static getSettingsCompanyDetailsLegalEntityList(
    legalEntities: SettingsCompanyDetailsLegalEntityDto[],
  ) {
    return (legalEntities || []).map((legalEntity) =>
      this.getSettingsCompanyDetailsData(legalEntity),
    );
  }

  private static getSettingsCompanyDetailsParentCompany(
    parentCompany: SettingsCompanyDetailsLegalEntityDto | null,
  ) {
    return parentCompany
      ? this.getSettingsCompanyDetailsData(parentCompany)
      : null;
  }

  private static getSettingsCompanyDetailsData(
    datum: SettingsCompanyDetailsLegalEntityDto,
  ): SettingsCompanyDetailsData {
    return {
      accountId: datum.accountId,
      address: datum.address,
      city: datum.city,
      country: datum.country,
      organizationName: datum.organizationName,
      poNumberRequired: datum.poNumberRequired,
      vatNumber: datum.vatNumber,
      zipcode: parseInt(datum.zipCode, 10),
    };
  }
}
