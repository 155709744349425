import { EnvironmentModel } from './environment.model';

export const environment: EnvironmentModel = {
  api: 'http://localhost:3000/',
  apimKey: '5f5a3d56d8d846eb9e52281f23edf1a7',
  appInsights: {
    instrumentationKey: '564fec46-8cf3-4f56-ab96-4bf565fa30ae',
  },
  authApiUrl: 'https://scecp-auth.dnv.com/Auth',
  baseUrl: 'https://portal.dev.dnv.com',
  certificateGraphqlHost:
    'https://sce-certservice-dev.azurewebsites.net/graphql',
  contactGraphqlHost: 'https://testapi.dnv.com/ba-scecp-contactservice-dev',
  contractsGraphqlHost:
    'https://sce-contractsservice-dev.azurewebsites.net/graphql',
  documentsApi: 'https://testapi.dnv.com/basceps-dev/v1/api/Documents',
  findingGraphqlHost: 'https://testapi.dnv.com/ba-scecp-findingservice-dev',
  invoicesGraphqlHost:
    'https://sce-invoiceservice-dev.azurewebsites.net/graphql',
  auditGraphqlHost: 'https://testapi.dnv.com/ba-scecp-auditservice-dev',
  newCertificateGraphqlHost:
    'https://testapi.dnv.com/ba-scecp-certificateservice-dev',
  powerBi: {
    accessToken: '',
    reportId: '4e19ccf9-1ff9-4a04-bd2d-1bab9cb1c029',
    embedUrl:
      'https://app.powerbi.com/reportEmbed?reportId=4e19ccf9-1ff9-4a04-bd2d-1bab9cb1c029&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLU5PUlRILUVVUk9QRS1FLVBSSU1BUlktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJlbWJlZEZlYXR1cmVzIjp7InVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJkaXNhYmxlQW5ndWxhckpTQm9vdHN0cmFwUmVwb3J0RW1iZWQiOnRydWV9fQ%3d%3d',
  },
  production: false,
  scheduleGraphqlHost:
    'https://sce-scheduleservice-dev.azurewebsites.net/graphql',
  newScheduleGraphqlHost:
    'https://testapi.dnv.com/ba-scecp-scheduleservice-dev',
  veracityUrl: 'https://id.veracity.com/',
};
