export enum ObjectType {
  Grid = 'Grid',
  Calendar = 'Calendar',
}

export enum PageName {
  AuditList = 'AuditList',
  AuditDetails = 'AuditDetails',
  FindingList = 'FindingList',
  FindingDetails = 'FindingDetails',
  CertificateList = 'CertificateList',
  CertificateDetails = 'CertificateDetails',
  ScheduleList = 'ScheduleList',
  ContractList = 'ContractList',
  FinancialList = 'FinancialList',
  SettingsAdmin = 'SettingsAdmin',
  SettingsCompanyDetails = 'SettingsCompanyDetails',
  SettingsMembers = 'SettingsMembers',
}

export enum ObjectName {
  Audits = 'Audits',
  Findings = 'Findings',
  SubAudits = 'SubAudits',
  Sites = 'Sites',
  Documents = 'Documents',
  Certificates = 'Certificates',
  Schedules = 'Schedules',
  Contracts = 'Contracts',
  Financials = 'Financials',
  Admin = 'Admin',
  CompanyDetails = 'CompanyDetails',
  Members = 'Members',
}
