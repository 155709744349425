export class LoadProfileData {
  static readonly type = '[Profile] Load Profile Data';
}

export class UpdateSubmitSettingsStatus {
  static readonly type = '[Profile] Update Submit Settings Status';

  constructor(public submitSettingsStatus: boolean) {}
}

export class UpdateSubmitSettingsStateValues {
  static readonly type = '[Profile] Update Submit Settings State Values';

  constructor(public submitSettingsValues: any) {}
}

export class UpdateSubmitSettingsValues {
  static readonly type = '[Profile] Update Submit Settings Values';
}
