import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { map } from 'rxjs';

import { AuthService } from '../services';

export const authGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.isUserAuthenticated().pipe(
    map((auth) => {
      if (auth) {
        return true;
      }

      return router.createUrlTree(['/welcome']);
    }),
  );
};
