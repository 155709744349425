import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map, Observable } from 'rxjs';

import { SETTINGS_ADMIN_LIST_QUERY } from '../../graphql';

@Injectable({ providedIn: 'root' })
export class SettingsAdminListService {
  private clientName = 'contact';

  constructor(private readonly apollo: Apollo) {}

  getSettingsAdminList(email: string): Observable<any> {
    return this.apollo
      .use(this.clientName)
      .query({
        query: SETTINGS_ADMIN_LIST_QUERY,
        variables: {
          email,
        },
      })
      .pipe(map((results: any) => results?.data?.adminList));
  }
}
