import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  effect,
  input,
  output,
  signal,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoDirective } from '@jsverse/transloco';
import { TreeNode } from 'primeng/api';
import { TooltipModule } from 'primeng/tooltip';
import { TreeNodeSelectEvent } from 'primeng/tree';
import { TreeSelectModule } from 'primeng/treeselect';
import {
  TriStateCheckboxChangeEvent,
  TriStateCheckboxModule,
} from 'primeng/tristatecheckbox';

import {
  SHARED_SELECT_TREE_OPTION_OVERFLOW_LIMIT,
  SHARED_SELECT_TREE_OPTION_TOOLTIP_DEFAULT_DELAY_MS,
  SHARED_SELECT_TREE_OPTION_TOOLTIP_LARGE_DELAY_MS,
  SHARED_SELECT_TREE_SCROLL_HEIGHT_PX,
  SHARED_SELECT_TREE_SEARCH_LIMIT,
} from './select-tree.constants';

export interface SharedSelectTreeChangeEventOutput {
  filter: number[];
  prefill: TreeNode[];
}

@Component({
  selector: 'shared-select-tree',
  imports: [
    CommonModule,
    TranslocoDirective,
    TreeSelectModule,
    TooltipModule,
    TriStateCheckboxModule,
    FormsModule,
  ],
  templateUrl: './select-tree.component.html',
  styleUrl: './select-tree.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedSelectTreeComponent {
  public options = input.required<TreeNode[]>();
  public placeholder = input<string>();
  public prefill = input<TreeNode[]>([]);

  public changeEvent = output<SharedSelectTreeChangeEventOutput>();

  public optionsTotal = computed(
    () => this.getAllAvailableOptions(this.options()).length,
  );
  public hasSearch = computed(
    () => this.options().length > SHARED_SELECT_TREE_SEARCH_LIMIT,
  );
  public hasTooltip = signal(false);
  public tooltipDelay = computed(() =>
    this.hasTooltip()
      ? SHARED_SELECT_TREE_OPTION_TOOLTIP_DEFAULT_DELAY_MS
      : SHARED_SELECT_TREE_OPTION_TOOLTIP_LARGE_DELAY_MS,
  );
  public scrollHeight = `${SHARED_SELECT_TREE_SCROLL_HEIGHT_PX}px`;
  public selected: TreeNode[] = [];
  public triState = signal<boolean | null>(this.getTriState());
  public selectOverflowLimit = SHARED_SELECT_TREE_OPTION_OVERFLOW_LIMIT;

  get selectedTooltip(): string {
    return this.getSelectedTooptip();
  }

  constructor(private ref: ChangeDetectorRef) {
    effect(() => {
      this.selected = this.getSelectedTrimmed();
      this.ref.markForCheck();
    });
  }

  onChange(_event: TreeNodeSelectEvent): void {
    this.changeEvent.emit({
      filter: Array.from(new Set(this.selected.map((s) => Number(s.data)))),
      prefill: this.selected,
    });
    this.hasTooltip.set(this.selected.length > 0);
    this.triState.set(this.getTriState());
  }

  onChangeTriState(event: TriStateCheckboxChangeEvent): void {
    event.originalEvent.stopImmediatePropagation();
    this.selected = this.getSelectedByTriState(event.value);
    this.changeEvent.emit({
      filter: Array.from(new Set(this.selected.map((s) => Number(s.data)))),
      prefill: this.selected,
    });
    this.hasTooltip.set(this.selected.length > 0);
  }

  private getSelectedByTriState(value: boolean | null): TreeNode[] {
    return value ? this.getAllAvailableOptions(this.options()) : [];
  }

  private getAllAvailableOptions(options?: TreeNode[]): TreeNode[] {
    return (options || []).reduce(
      (acc: TreeNode[], option) =>
        [
          ...acc,
          option,
          ...(option?.children?.length
            ? this.getAllAvailableOptions(option.children)
            : []),
        ] as TreeNode[],
      [] as TreeNode[],
    );
  }

  private getSelectedTrimmed(): TreeNode[] {
    const keys = this.getAllAvailableOptions(this.options()).map((o) => o.key);

    return this.selected.filter((s) => keys.includes(s.key));
  }

  private getSelectedTooptip(): string {
    return this.selected.map((s) => s.label).join(', ');
  }

  private getTriState(): boolean | null {
    return this.selected.length !== 0
      ? this.optionsTotal() === this.selected.length
      : null;
  }
}
