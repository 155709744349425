import { Selector } from '@ngxs/store';

import { ProfileInformationModel } from '../../models';
import { SettingsState, SettingsStateModel } from '../settings.state';

export class ProfileSelectors {
  @Selector([SettingsState])
  static profileInformation(
    state: SettingsStateModel,
  ): ProfileInformationModel {
    return state.information;
  }

  @Selector([SettingsState])
  static submitSettingsStatus(state: SettingsStateModel): boolean {
    return state.submitSettingsStatus;
  }
}
