import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';

import { SpinnerComponent } from '@customer-portal/core';
import { ProfileLanguageStoreService } from '@customer-portal/data-access/settings';
import {
  AuthService,
  CustomConfirmDialogComponent,
  CustomToastComponent,
} from '@customer-portal/shared';

import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SidebarMobileComponent } from './components/sidebar-mobile/sidebar-mobile.component';

@Component({
  imports: [
    ButtonModule,
    RouterModule,
    SpinnerComponent,
    CustomConfirmDialogComponent,
    CustomToastComponent,
    ToastModule,
    BreadcrumbComponent,
    NavbarComponent,
    SidebarComponent,
    SidebarMobileComponent,
  ],
  providers: [ProfileLanguageStoreService, ConfirmationService],
  selector: 'customer-portal-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'customer-portal';
  isLoggedIn = false;

  constructor(
    private readonly profileLanguageStoreService: ProfileLanguageStoreService,
    private readonly authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.authService.isUserAuthenticated().subscribe((result) => {
      this.isLoggedIn = !!result;
    });
    this.profileLanguageStoreService.loadProfileLanguage();
  }
}
