import { Language } from '@customer-portal/shared';

import { ProfileDto } from '../../dtos';
import { ProfileModel, ProfileSettingsLanguagesModel } from '../../models';

export class ProfileMapperService {
  static mapToProfileItemModel(dto: ProfileDto): ProfileModel | null {
    if (!dto?.data) {
      return null;
    }

    const { data } = dto;
    const profile = data;

    const languagesList: ProfileSettingsLanguagesModel[] = Object.keys(
      Language,
    ).map((key) => ({
      languageName: key,
      isSelected: key === profile.communicationLanguage,
    }));

    return {
      information: {
        firstName: profile.firstName,
        lastName: profile.lastName,
        displayName: profile.displayName,
        country: profile.country,
        region: profile.region,
        email: profile.email,
        phone: profile.phone,
        portalLanguage: profile.portalLanguage,
        communicationLanguage: profile.communicationLanguage,
        jobTitle: profile.jobTitle,
        languages: languagesList,
      },
    };
  }
}
