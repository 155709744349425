import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { TreeNode } from 'primeng/api';
import { TreeTableModule } from 'primeng/treetable';

import { TreeColumnDefinition } from '../../models';

@Component({
  selector: 'shared-tree-table',
  imports: [CommonModule, TranslocoDirective, TreeTableModule],
  templateUrl: './tree-table.component.html',
  styleUrl: './tree-table.component.scss',
})
export class TreeTableComponent {
  private _data: TreeNode[] = [];

  @Input()
  get data(): TreeNode[] {
    return this._data;
  }

  set data(value: TreeNode[]) {
    this._data = value.map((item) => structuredClone(item));
  }

  @Input() cols: TreeColumnDefinition[] = [];
  @Input() categories: Map<number, string> = new Map();
}
