import { GridConfig } from '@customer-portal/shared';

import {
  SettingsMembersItemModel,
  SettingsNewMemberFormModel,
} from '../../models';

export class LoadSettingsMembersList {
  static readonly type = '[Settings] Load Members List';
}

export class LoadSettingsMembersListSuccess {
  static readonly type = '[Settings] Load Members List Success';

  constructor(public membersList: SettingsMembersItemModel[]) {}
}

export class UpdateMembersGridConfig {
  static readonly type = '[Settings] Update Members Grid Config';

  constructor(public membersGridConfig: GridConfig) {}
}

export class UpdateMembersFilterOptions {
  static readonly type = '[Settings] Update Members Filter Options';
}

export class ResetMembersListState {
  static readonly type = '[Settings] Reset Members List State';
}

export class LoadSettingsAdminList {
  static readonly type = '[Settings] Load Admin List';
}

export class LoadSettingsAdminListSuccess {
  static readonly type = '[Settings] Load Admin List Success';

  constructor(public adminList: SettingsMembersItemModel[]) {}
}

export class UpdateAdminGridConfig {
  static readonly type = '[Settings] Update Admin Grid Config';

  constructor(public adminGridConfig: GridConfig) {}
}

export class UpdateAdminFilterOptions {
  static readonly type = '[Settings] Update Admin Filter Options';
}

export class ResetAdminListState {
  static readonly type = '[Settings] Reset Admin List State';
}

export class LoadMemberRoles {
  static readonly type = '[Settings] Load Members Roles';
}

export class LoadMemberRolesSuccess {
  static readonly type = '[Settings] Load Members Roles Success';

  constructor(public roles: string[]) {}
}

export class UpdateContinueToPermissionsStatus {
  static readonly type = '[Settings] Update Continue To Permissions Status';

  constructor(public isAddMemberFormValid: boolean) {}
}

export class UpdateNewMemberForm {
  static readonly type = '[Settings] Update New Member Form';

  constructor(public newMemberForm: SettingsNewMemberFormModel) {}
}

export class ContinueToPermissions {
  static readonly type = '[Settings] Continue To Permissions';
}

export class LoadMembersPermissions {
  static readonly type = '[Settings] Load Members Permissions';

  constructor(
    public memberEmail: string,
    public userEmail: string,
  ) {}
}

export class LoadMembersPerimissionsCountries {
  static readonly type = '[Settings] Load Members Permissions Countries';

  constructor(public countries: any[]) {}
}

export class LoadMembersPerimissionsServices {
  static readonly type = '[Settings] Load Members Permissions Services';

  constructor(public services: any[]) {}
}

export class LoadMembersPermissionsSites {
  static readonly type = '[Settings] Load Members Permissions Sites';

  constructor(public sites: any[]) {}
}
