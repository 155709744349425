import { Injectable, Signal } from '@angular/core';
import { Store } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { TreeNode } from 'primeng/api';
import { Observable } from 'rxjs';

import {
  FilteringConfig,
  FilterOptions,
  GridConfig,
} from '@customer-portal/shared';

import { SettingsNewMemberFormModel } from '../../models';
import {
  ContinueToPermissions,
  LoadMemberRoles,
  LoadMembersPermissions,
  LoadSettingsAdminList,
  LoadSettingsMembersList,
  ResetAdminListState,
  ResetMembersListState,
  UpdateAdminGridConfig,
  UpdateContinueToPermissionsStatus,
  UpdateMembersGridConfig,
  UpdateNewMemberForm,
} from '../actions';
import { SettingsMembersSelectors } from '../selectors';

@Injectable({ providedIn: 'root' })
export class SettingsMembersStoreService {
  constructor(private store: Store) {}

  get settingsMembersList() {
    return this.store.selectSignal(
      SettingsMembersSelectors.settingsMembersList,
    );
  }

  get membersTotalFilteredRecords(): Signal<number> {
    return this.store.selectSignal(
      SettingsMembersSelectors.membersTotalFilteredRecords,
    );
  }

  get hasMembersActiveFilters(): Signal<boolean> {
    return this.store.selectSignal(
      SettingsMembersSelectors.hasMembersActiveFilters,
    );
  }

  get membersFilterOptions(): Signal<FilterOptions> {
    return this.store.selectSignal(
      SettingsMembersSelectors.membersFilterOptions,
    );
  }

  get membersFilteringConfig(): Observable<FilteringConfig> {
    return this.store.select(SettingsMembersSelectors.membersFilteringConfig);
  }

  get settingsAdminList() {
    return this.store.selectSignal(SettingsMembersSelectors.settingsAdminList);
  }

  get adminTotalFilteredRecords(): Signal<number> {
    return this.store.selectSignal(
      SettingsMembersSelectors.adminTotalFilteredRecords,
    );
  }

  get hasAdminActiveFilters(): Signal<boolean> {
    return this.store.selectSignal(
      SettingsMembersSelectors.hasAdminActiveFilters,
    );
  }

  get adminFilterOptions(): Signal<FilterOptions> {
    return this.store.selectSignal(SettingsMembersSelectors.adminFilterOptions);
  }

  get adminFilteringConfig(): Observable<FilteringConfig> {
    return this.store.select(SettingsMembersSelectors.adminFilteringConfig);
  }

  get memberRoles(): Signal<string[]> {
    return this.store.selectSignal(SettingsMembersSelectors.memberRoles);
  }

  get isAddMemberFormValid(): Signal<boolean> {
    return this.store.selectSignal(
      SettingsMembersSelectors.isAddMemberFormValid,
    );
  }

  get sites(): Signal<TreeNode[]> {
    return this.store.selectSignal(SettingsMembersSelectors.sites);
  }

  get services(): Signal<TreeNode[]> {
    return this.store.selectSignal(SettingsMembersSelectors.services);
  }

  get newMemberForm(): Signal<SettingsNewMemberFormModel | null> {
    return this.store.selectSignal(SettingsMembersSelectors.newMemberForm);
  }

  @Dispatch()
  loadSettingsMembersList = () => new LoadSettingsMembersList();

  @Dispatch()
  updateMembersGridConfig = (gridConfig: GridConfig) =>
    new UpdateMembersGridConfig(gridConfig);

  @Dispatch()
  resetMembersListState = () => new ResetMembersListState();

  @Dispatch()
  loadSettingsAdminList = () => new LoadSettingsAdminList();

  @Dispatch()
  updateAdminGridConfig = (gridConfig: GridConfig) =>
    new UpdateAdminGridConfig(gridConfig);

  @Dispatch()
  resetAdminListState = () => new ResetAdminListState();

  @Dispatch()
  loadMemberRoles = () => new LoadMemberRoles();

  @Dispatch()
  updateContinueToPermissionsStatus = (isValid: boolean) =>
    new UpdateContinueToPermissionsStatus(isValid);

  @Dispatch()
  updateNewMemberForm = (formValue: SettingsNewMemberFormModel) =>
    new UpdateNewMemberForm(formValue);

  @Dispatch()
  continueToPermissions = () => new ContinueToPermissions();

  @Dispatch()
  loadMembersPermissions = (memberEmail: string, userEmail: string) =>
    new LoadMembersPermissions(memberEmail, userEmail);
}
