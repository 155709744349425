import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@customer-portal/environments';
import { FileUpload, getContentType } from '@customer-portal/shared';

import { DocumentDeleteDto } from '../dtos/document-delete.dto';

@Injectable({ providedIn: 'root' })
export class DocumentsService {
  constructor(private readonly httpClient: HttpClient) {}

  downloadDocument(
    documentId: number,
    fileName?: string,
  ): Observable<HttpResponse<Blob>> {
    const { documentsApi } = environment;

    const query = `documentId=${documentId}`;
    const url = `${documentsApi}/download?${query}`;

    const headers = fileName
      ? new HttpHeaders({
          Accept: getContentType(fileName),
        })
      : undefined;

    return this.httpClient.get<Blob>(url, {
      observe: 'response',
      responseType: 'blob' as 'json',
      headers,
    });
  }

  deleteDocument(id: number): Observable<DocumentDeleteDto> {
    const { documentsApi } = environment;

    return this.httpClient.delete<DocumentDeleteDto>(
      `${documentsApi}/DeleteDocument?documentId=${id}`,
    );
  }

  uploadDocument(
    url: string,
    file: File,
    auditId: string,
    findingId?: string,
  ): Observable<FileUpload[]> {
    const formData: FormData = new FormData();
    formData.append('files', file, file.name);

    const headers = new HttpHeaders({ SKIP_LOADING: 'true' });

    const params = findingId
      ? this.uploadFindingDocumentParams(auditId, findingId)
      : this.uploadAuditDocumentParams(auditId);

    const { documentsApi } = environment;

    return this.httpClient.post<FileUpload[]>(
      `${documentsApi}${url}`,
      formData,
      {
        headers,
        params,
      },
    );
  }

  uploadAuditDocumentParams(auditId: string): HttpParams {
    return new HttpParams().set('AuditId', auditId);
  }

  uploadFindingDocumentParams(auditId: string, findingId: string): HttpParams {
    return new HttpParams().set('AuditId', auditId).set('FindingId', findingId);
  }

  downloadAllDocuments(ids: number[]): Observable<HttpResponse<Blob>> {
    const { documentsApi } = environment;
    const url = `${documentsApi}/Bulkdownload`;

    return this.httpClient.post<Blob>(url, ids, {
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }
}
