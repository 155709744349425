import { GridConfig } from '@customer-portal/shared';

import {
  SettingsCompanyDetailsEditParams,
  SettingsCompanyDetailsModel,
} from '../../models';

export class LoadSettingsCompanyDetails {
  static readonly type = '[Settings] Load Company Details';
}

export class LoadSettingsCompanyDetailsSuccess {
  static readonly type = '[Settings] Load Company Details Success';

  constructor(public companyDetails: SettingsCompanyDetailsModel) {}
}

export class UpdateSettingsCompanyDetails {
  static readonly type = '[Settings] Update Company Details';

  constructor(public params: SettingsCompanyDetailsEditParams) {}
}

export class UpdateSettingsCompanyDetailsEntityListFilterOptions {
  static readonly type =
    '[Settings] Update Company Details Entity List Filter Options';
}

export class UpdateSettingsCompanyDetailsEntityListGridConfig {
  static readonly type =
    '[Settings] Update Company Details Entity List Grid Config';

  constructor(public legalEntityGridConfig: GridConfig) {}
}

export class ResetSettingsCompanyDetailsState {
  static readonly type = '[Settings] Reset Company Details State';
}
