import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map, Observable } from 'rxjs';

import { SETTINGS_MEMBERS_PERMISSIONS_QUERY } from '../../graphql';

@Injectable({ providedIn: 'root' })
export class SettingsMembersPermissionsService {
  private clientName = 'contact';

  constructor(private readonly apollo: Apollo) {}

  getSettingsMembersPermissions(
    memberEmail: string,
    userEmail: string,
  ): Observable<any> {
    return this.apollo
      .use(this.clientName)
      .query({
        query: SETTINGS_MEMBERS_PERMISSIONS_QUERY,
        variables: {
          memberEmail,
          userEmail,
        },
      })
      .pipe(map((results: any) => results?.data?.getUserPermissions));
  }
}
