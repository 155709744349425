import { Selector } from '@ngxs/store';
import { TreeNode } from 'primeng/api';

import {
  applyGridConfig,
  FilteringConfig,
  FilterOptions,
  getNumberOfFilteredRecords,
  isAnyFilterActive,
} from '@customer-portal/shared';

import {
  SettingsMembersItemModel,
  SettingsNewMemberFormModel,
} from '../../models';
import { SettingsState, SettingsStateModel } from '../settings.state';

export class SettingsMembersSelectors {
  @Selector([SettingsMembersSelectors._settingsMembersList])
  static settingsMembersList(
    settingsMembersList: SettingsMembersItemModel[],
  ): SettingsMembersItemModel[] {
    return settingsMembersList;
  }

  @Selector([SettingsMembersSelectors._membersTotalFilteredRecords])
  static membersTotalFilteredRecords(
    membersTotalFilteredRecords: number,
  ): number {
    return membersTotalFilteredRecords;
  }

  @Selector([SettingsMembersSelectors._membersFilterOptions])
  static membersFilterOptions(
    membersFilterOptions: FilterOptions,
  ): FilterOptions {
    return membersFilterOptions;
  }

  @Selector([SettingsMembersSelectors._membersFilteringConfig])
  static membersFilteringConfig(
    membersFilteringConfig: FilteringConfig,
  ): FilteringConfig {
    return membersFilteringConfig;
  }

  @Selector([SettingsMembersSelectors._hasMembersActiveFilters])
  static hasMembersActiveFilters(hasMembersActiveFilters: boolean): boolean {
    return hasMembersActiveFilters;
  }

  @Selector([SettingsMembersSelectors._settingsAdminList])
  static settingsAdminList(
    settingsAdminList: SettingsMembersItemModel[],
  ): SettingsMembersItemModel[] {
    return settingsAdminList;
  }

  @Selector([SettingsMembersSelectors._adminTotalFilteredRecords])
  static adminTotalFilteredRecords(adminTotalFilteredRecords: number): number {
    return adminTotalFilteredRecords;
  }

  @Selector([SettingsMembersSelectors._adminFilterOptions])
  static adminFilterOptions(adminFilterOptions: FilterOptions): FilterOptions {
    return adminFilterOptions;
  }

  @Selector([SettingsMembersSelectors._adminFilteringConfig])
  static adminFilteringConfig(
    adminFilteringConfig: FilteringConfig,
  ): FilteringConfig {
    return adminFilteringConfig;
  }

  @Selector([SettingsMembersSelectors._hasAdminActiveFilters])
  static hasAdminActiveFilters(hasAdminActiveFilters: boolean): boolean {
    return hasAdminActiveFilters;
  }

  @Selector([SettingsMembersSelectors._memberRoles])
  static memberRoles(memberRoles: string[]): string[] {
    return memberRoles;
  }

  @Selector([SettingsMembersSelectors._isAddMemberFormValid])
  static isAddMemberFormValid(isAddMemberFormValid: boolean): boolean {
    return isAddMemberFormValid;
  }

  @Selector([SettingsMembersSelectors._services])
  static services(services: TreeNode[]): TreeNode[] {
    return structuredClone(services);
  }

  @Selector([SettingsMembersSelectors._sites])
  static sites(sites: TreeNode[]): TreeNode[] {
    return structuredClone(sites);
  }

  @Selector([SettingsMembersSelectors._newMemberForm])
  static newMemberForm(
    newMemberForm: SettingsNewMemberFormModel | null,
  ): SettingsNewMemberFormModel | null {
    return newMemberForm;
  }

  @Selector([SettingsState])
  private static _settingsMembersList(
    state: SettingsStateModel,
  ): SettingsMembersItemModel[] {
    const { membersList, membersGridConfig } = state;

    return applyGridConfig(membersList, membersGridConfig);
  }

  @Selector([SettingsState])
  private static _membersTotalFilteredRecords(
    state: SettingsStateModel,
  ): number {
    const { membersList, membersGridConfig } = state;

    return getNumberOfFilteredRecords(membersList, membersGridConfig);
  }

  @Selector([SettingsState])
  private static _membersFilterOptions(
    state: SettingsStateModel,
  ): FilterOptions {
    return state.membersFilterOptions;
  }

  @Selector([SettingsState])
  private static _membersFilteringConfig(
    state: SettingsStateModel,
  ): FilteringConfig {
    return state.membersGridConfig.filtering;
  }

  @Selector([SettingsState])
  private static _hasMembersActiveFilters(state: SettingsStateModel): boolean {
    return isAnyFilterActive(state.membersGridConfig.filtering);
  }

  @Selector([SettingsState])
  private static _settingsAdminList(
    state: SettingsStateModel,
  ): SettingsMembersItemModel[] {
    const { adminList, adminGridConfig } = state;

    return applyGridConfig(adminList, adminGridConfig);
  }

  @Selector([SettingsState])
  private static _adminTotalFilteredRecords(state: SettingsStateModel): number {
    const { adminList, adminGridConfig } = state;

    return getNumberOfFilteredRecords(adminList, adminGridConfig);
  }

  @Selector([SettingsState])
  private static _adminFilterOptions(state: SettingsStateModel): FilterOptions {
    return state.adminFilterOptions;
  }

  @Selector([SettingsState])
  private static _adminFilteringConfig(
    state: SettingsStateModel,
  ): FilteringConfig {
    return state.adminGridConfig.filtering;
  }

  @Selector([SettingsState])
  private static _hasAdminActiveFilters(state: SettingsStateModel): boolean {
    return isAnyFilterActive(state.adminGridConfig.filtering);
  }

  @Selector([SettingsState])
  private static _memberRoles(state: SettingsStateModel): string[] {
    return state.memberRoles;
  }

  @Selector([SettingsState])
  private static _isAddMemberFormValid(state: SettingsStateModel): boolean {
    return state.isAddMemberFormValid;
  }

  @Selector([SettingsState])
  private static _services(state: SettingsStateModel): TreeNode[] {
    return state.services;
  }

  @Selector([SettingsState])
  private static _sites(state: SettingsStateModel): TreeNode[] {
    return state.sites;
  }

  @Selector([SettingsState])
  private static _newMemberForm(
    state: SettingsStateModel,
  ): SettingsNewMemberFormModel | null {
    return state.newMemberForm;
  }
}
