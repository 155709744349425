import { TreeNode } from 'primeng/api';

import {
  COLUMN_DELIMITER,
  CompanyModel,
  GridEventActionType,
} from '@customer-portal/shared';

import {
  Country,
  SettingsAdminListDto,
  SettingsAdminListItemDto,
  SettingsMembersListDto,
  SettingsMembersListItemDto,
  SettingsMembersPermissionsDto,
} from '../../dtos';
import { SettingsMembersItemModel } from '../../models';

const extractUniqueSiteNames = (countries: Country[]): string[] => {
  const siteNamesSet = new Set<string>();

  countries.forEach((country) => {
    country.cities.forEach((city) => {
      city.sites.forEach((site) => {
        siteNamesSet.add(site.siteName);
      });
    });
  });

  return Array.from(siteNamesSet);
};

const extractUniqueCompanyNames = (companies: CompanyModel[]) =>
  Array.from(new Set(companies.map((company) => company.companyName)));

const isUserPendingVerification = (userStatus: string) =>
  userStatus.toLowerCase() === 'in progress';

export class SettingsMembersMapper {
  static mapToMembersList(
    dto: SettingsMembersListDto,
  ): SettingsMembersItemModel[] {
    if (!dto?.data) {
      return [];
    }

    const { data } = dto;

    return data.map((member: SettingsMembersListItemDto) => {
      const services = Array.from(
        new Set(
          member.services.map((auditService) => auditService.serviceName),
        ),
      ).join(COLUMN_DELIMITER);

      const companies = extractUniqueCompanyNames(member.companies).join(
        COLUMN_DELIMITER,
      );

      const sites = extractUniqueSiteNames(member.countries).join(
        COLUMN_DELIMITER,
      );

      const { name, email, userStatus, roles: access } = member;

      return {
        name,
        email,
        company: companies,
        services,
        sites,
        access,
        iconTooltip: {
          displayIcon: isUserPendingVerification(userStatus),
          iconClass: 'pi pi-info-circle',
          iconPosition: 'suffix',
          tooltipMessage: 'settings.membersTab.pendingVerification',
        },
        isDisabled: isUserPendingVerification(userStatus),
        eventActions: !isUserPendingVerification(userStatus)
          ? {
              id: email,
              actions: [
                {
                  label: GridEventActionType.ManagePermissions,
                  i18nKey: `gridEvent.${GridEventActionType.ManagePermissions}`,
                  icon: 'pi pi-lock',
                  disabled: true,
                },
                {
                  label: GridEventActionType.Remove,
                  i18nKey: `gridEvent.${GridEventActionType.Remove}`,
                  icon: 'pi pi-trash',
                  disabled: true,
                },
              ],
            }
          : undefined,
      };
    });
  }

  static mapToAdminList(dto: SettingsAdminListDto): SettingsMembersItemModel[] {
    if (!dto?.data) {
      return [];
    }

    const { data } = dto;

    return data.map((admin: SettingsAdminListItemDto) => {
      const company = extractUniqueCompanyNames(admin.companies).join(
        COLUMN_DELIMITER,
      );

      const { name, email, userStatus, isCurrentUser } = admin;

      // TODO: translate the strings
      return {
        name,
        email,
        company,
        services: 'All Services',
        sites: 'All Sites',
        access: 'Admin',
        isDisabled: isUserPendingVerification(userStatus),
        iconTooltip: {
          displayIcon: isUserPendingVerification(userStatus),
          iconClass: 'pi pi-info-circle',
          iconPosition: 'suffix',
          tooltipMessage: 'settings.membersTab.pendingVerification',
        },
        eventActions:
          !isUserPendingVerification(userStatus) && !isCurrentUser
            ? {
                id: email,
                actions: [
                  {
                    label: GridEventActionType.ManagePermissions,
                    i18nKey: `gridEvent.${GridEventActionType.ManagePermissions}`,
                    icon: 'pi pi-lock',
                    disabled: true,
                  },
                  {
                    label: GridEventActionType.Remove,
                    i18nKey: `gridEvent.${GridEventActionType.Remove}`,
                    icon: 'pi pi-trash',
                    disabled: true,
                  },
                ],
              }
            : undefined,
      };
    });
  }

  static mapToMemberServices(dto: SettingsMembersPermissionsDto): TreeNode[] {
    const services: TreeNode[] = [];

    dto.data.forEach((data) => {
      data.companies.forEach((company) => {
        company.services.forEach((service) => {
          services.push({
            data: service.serviceId,
            label: service.serviceName,
            children: [],
          });
        });
      });
    });

    return services;
  }

  static mapToMemberSites(dto: SettingsMembersPermissionsDto): TreeNode[] {
    const tree: TreeNode[] = [];

    dto.data.forEach((data) => {
      data.companies.forEach((company) => {
        company.services.forEach((service) => {
          service.countries.forEach((country) => {
            const countryNode: TreeNode = {
              data: country.countryName,
              label: country.countryName,
              children: [],
            };

            country.cities.forEach((city) => {
              const cityNode: TreeNode = {
                data: city.cityName,
                label: city.cityName,
                children: [],
              };

              city.sites.forEach((site) => {
                const siteNode: TreeNode = {
                  data: site.siteId,
                  label: site.siteName,
                };

                cityNode.children!.push(siteNode);
              });

              countryNode.children!.push(cityNode);
            });

            tree.push(countryNode);
          });
        });
      });
    });

    return tree;
  }
}
