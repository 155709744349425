export enum TimeRange {
  Custom = 'custom',
  MonthPrevious = 'monthPrevious',
  MonthCurrent = 'monthCurrent',
  MonthNext = 'monthNext',
  YearPrevious = 'yearPrevious',
  YearCurrent = 'yearCurrent',
  YearNext = 'yearNext',
  YearCustom = 'yearCustom',
}
