import { ChartFilter, FilterValue, TrendsChartFilter } from '../../models';
import { getYearBounds } from '../date';

const initializeTooltipContainer = (chart: any): HTMLDivElement => {
  let tooltipContainer = chart.canvas.parentNode.querySelector(
    'div.tooltip',
  ) as HTMLDivElement;

  if (!tooltipContainer) {
    tooltipContainer = document.createElement('div');
    tooltipContainer.classList.add('tooltip');

    // Tooltip base styles
    Object.assign(tooltipContainer.style, {
      background: 'rgba(255, 255, 255, 1)',
      borderRadius: '8px',
      color: 'white',
      opacity: 1,
      position: 'absolute',
      transform: 'translate(-50%, 0)',
      transition: 'all .4s ease',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      zIndex: '1000',
      pointerEvents: 'auto',
    });

    // Tooltip mouse events for opacity
    tooltipContainer.onmouseover = () => {
      tooltipContainer.style.opacity = '1';
      tooltipContainer.style.pointerEvents = 'auto';
    };

    tooltipContainer.onmouseout = () => {
      tooltipContainer.style.opacity = '0';
      tooltipContainer.style.pointerEvents = 'none';
    };

    chart.canvas.parentNode.appendChild(tooltipContainer);
  }

  return tooltipContainer;
};

const createTooltipTitle = (titleText: string): HTMLDivElement => {
  const titleContainer = document.createElement('div');
  const titleElement = document.createElement('p');
  titleElement.textContent = titleText;

  Object.assign(titleElement.style, {
    fontWeight: 'bold',
    fontSize: '16px',
    color: '#0F204B',
    marginTop: '10px',
    marginLeft: '10px',
    marginRight: '10px',
  });

  titleContainer.appendChild(titleElement);

  return titleContainer;
};

const createTooltipDataEntry = (
  label: string,
  value: string,
  color: string,
  percentage?: string,
): HTMLParagraphElement => {
  const entryContainer = document.createElement('p');
  const icon = document.createElement('span');
  const labelText = document.createElement('span');

  Object.assign(icon.style, {
    background: color,
    borderWidth: '2px',
    borderRadius: '50%',
    marginRight: '10px',
    height: '10px',
    width: '10px',
    display: 'inline-block',
  });

  Object.assign(labelText.style, {
    fontWeight: '400',
    fontSize: '16px',
    color: '#000',
    display: 'inline-block',
  });

  const innerHTML = `${label}: <b>${value}</b>`;

  labelText.innerHTML = percentage
    ? `${innerHTML} (${percentage}%)`
    : innerHTML;

  entryContainer.appendChild(icon);
  entryContainer.appendChild(labelText);
  Object.assign(entryContainer.style, {
    display: 'block',
    whiteSpace: 'nowrap',
    marginLeft: '10px',
    marginRight: '10px',
  });

  return entryContainer;
};

const createTooltipButton = (onclick: () => void): HTMLButtonElement => {
  const button = document.createElement('button');
  // TODO translate this label
  button.textContent = 'View in list \u279C';
  Object.assign(button.style, {
    backgroundColor: '#fff',
    border: '1px solid #003591',
    color: '#003591',
    borderRadius: '10px',
    padding: '8px 12px',
    marginTop: '4px',
    marginBottom: '10px',
    marginLeft: '10px',
    marginRight: '10px',
    fontSize: '14px',
    fontWeight: '700',
    whiteSpace: 'nowrap',
  });

  button.onclick = onclick;

  return button;
};

const getBodyTextAndLabel = (
  chart: any,
  tooltip: any,
  index: number,
  customTitle?: string,
) => {
  let bodyText = tooltip.body[index]?.lines?.join(', ') || '';
  let label = customTitle ? tooltip.title[index] : '';

  const scales = chart.config.options?.scales;
  const hasTwoAxes = scales && Object.keys(scales).length === 2;

  if (hasTwoAxes) {
    const [category, value] = bodyText
      .split(': ')
      .map((part: string) => part?.trim());

    if (category && value) {
      label = category;
      bodyText = value;
    }
  }

  return {
    label,
    bodyText,
  };
};

const extractTitleFilterValues = (
  title: string[],
  titleFilter: string | TrendsChartFilter | undefined,
  bodyFilter: string,
): FilterValue[] => {
  const titleValue = title[0];

  if (!titleFilter || typeof titleFilter === 'string') {
    return titleValue
      ? [
          {
            label: titleFilter || bodyFilter,
            value: [{ label: titleValue, value: titleValue }],
          },
        ]
      : [];
  }

  if ('field' in titleFilter) {
    const { firstDay, lastDay } = getYearBounds(titleValue);

    return [
      {
        label: titleFilter.field,
        value: [
          { label: firstDay, value: firstDay },
          { label: lastDay, value: lastDay },
        ],
      },
    ];
  }

  return [];
};

const extractBodyFilterValues = (
  body: { lines: string[] }[],
  titleFilter: string | TrendsChartFilter | undefined,
  bodyFilter: string,
): FilterValue[] => {
  if (!titleFilter) return [];

  return body.flatMap(({ lines }) =>
    lines.map((line) => {
      const [label] = line.split(':');

      return { label: bodyFilter, value: [{ label, value: label }] };
    }),
  );
};

export const handleTooltipButtonClick = (
  tooltip: { title: string[]; body: { lines: string[] }[] },
  chartFilters: ChartFilter,
  onTooltipButtonClick: (data: FilterValue[]) => void,
): void => {
  if (!onTooltipButtonClick) return;

  const { bodyFilter, titleFilter } = chartFilters;
  const { title, body } = tooltip;

  const titleFilterValues = extractTitleFilterValues(
    title,
    titleFilter,
    bodyFilter,
  );

  const bodyFilterValues = extractBodyFilterValues(
    body,
    titleFilter,
    bodyFilter,
  );

  const filterValues = [...bodyFilterValues, ...titleFilterValues];

  onTooltipButtonClick(filterValues);
};

const positionTooltip = (
  tooltipContainer: HTMLElement,
  chart: any,
  tooltip: any,
) => {
  const TOOLTIP_OFFSET = 15;
  const { clientWidth, clientHeight } = tooltipContainer;
  const { width, height } = chart.canvas;
  const { _eventPosition: eventPosition, options } = tooltip;
  const { bodyFont, padding } = options;

  let coordinateLeft;
  let coordinateTop;

  if (eventPosition.x < clientWidth) {
    coordinateLeft = eventPosition.x + clientWidth / 2;
  } else if (width - eventPosition.x < clientWidth) {
    coordinateLeft = eventPosition.x - clientWidth / 2;
  } else {
    coordinateLeft = eventPosition.x;
  }

  if (eventPosition.y < clientHeight) {
    coordinateTop = eventPosition.y + clientHeight / 2;
  } else if (height - eventPosition.y < clientHeight) {
    coordinateTop = eventPosition.y - clientHeight / 2 - TOOLTIP_OFFSET;
  } else {
    coordinateTop = eventPosition.y - clientHeight / 2 - TOOLTIP_OFFSET;
  }

  Object.assign(tooltipContainer.style, {
    opacity: 1,
    left: `${coordinateLeft}px`,
    top: `${coordinateTop}px`,
    font: bodyFont.string,
    padding: `${padding}px`,
  });
};

export const externalTooltipPlugin = (
  context: any,
  onTooltipButtonClick: (data: any) => void,
  chartFilters: ChartFilter,
  customTitle?: string,
  shouldShowPercentage?: boolean,
) => {
  const { chart, tooltip } = context;
  const tooltipContainer = initializeTooltipContainer(chart);

  // Hide tooltip if there's no data to show
  if (tooltip.opacity === 0) {
    tooltipContainer.style.opacity = '0';

    return;
  }

  tooltipContainer.style.pointerEvents = 'auto';

  // Clear existing content
  tooltipContainer.innerHTML = '';

  // Add custom title if provided
  const titleText = customTitle || (tooltip.title && tooltip.title.join(', '));

  if (titleText) {
    tooltipContainer.appendChild(createTooltipTitle(titleText));
  }

  // Populate tooltip with data entries
  if (tooltip.body) {
    tooltip.body.forEach((_bodyItem: string, index: number) => {
      const labelColor = tooltip.labelColors[index].backgroundColor;
      const { label, bodyText } = getBodyTextAndLabel(
        chart,
        tooltip,
        index,
        customTitle,
      );

      let dataEntry: HTMLParagraphElement;

      if (shouldShowPercentage) {
        const percentage = context.chart.config.data.percentageValues[label];

        dataEntry = createTooltipDataEntry(
          label,
          bodyText,
          labelColor,
          percentage,
        );
      } else {
        dataEntry = createTooltipDataEntry(label, bodyText, labelColor);
      }

      tooltipContainer.appendChild(dataEntry);
    });

    // Add "View Details" button
    const button = createTooltipButton(() =>
      handleTooltipButtonClick(tooltip, chartFilters, onTooltipButtonClick),
    );

    tooltipContainer.appendChild(button);
  }

  positionTooltip(tooltipContainer, chart, tooltip);
};

export const exportedForTesting = {
  createTooltipTitle,
  createTooltipDataEntry,
  createTooltipButton,
  getBodyTextAndLabel,
  handleTooltipButtonClick,
  positionTooltip,
};
