import { gql } from 'apollo-angular';

export const SETTINGS_MEMBERS_PERMISSIONS_QUERY = gql`
  query GetSettingsMembersPermissions(
    $memberEmail: String
    $userEmail: String
  ) {
    getUserPermissions(memberEmail: $memberEmail, userEmail: $userEmail) {
      data {
        companies {
          companyId
          companyName
          hasParentId
          isCompanySelected
          parentId
          services {
            countries {
              cities {
                cityName
                isCitySelected
                sites {
                  isSiteSelected
                  siteId
                  siteName
                }
              }
              countryName
              isCountrySelected
            }
            isServiceSelected
            serviceId
            serviceName
          }
        }
        userId
      }
      errorCode
      isSuccess
      message
    }
  }
`;
