import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  input,
  output,
  signal,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoDirective } from '@jsverse/transloco';
import { MultiSelectChangeEvent, MultiSelectModule } from 'primeng/multiselect';
import {
  TriStateCheckboxChangeEvent,
  TriStateCheckboxModule,
} from 'primeng/tristatecheckbox';

import {
  CUSTOM_MULTISELECT_OPTION_HEIGHT,
  CUSTOM_MULTISELECT_SCROLL_BAR_LIMIT,
  CUSTOM_MULTISELECT_SEARCH_BAR_LIMIT,
} from './custom-multiselect.constants';

@Component({
  selector: 'shared-custom-multiselect',
  imports: [
    CommonModule,
    FormsModule,
    MultiSelectModule,
    TriStateCheckboxModule,
    TranslocoDirective,
  ],
  templateUrl: './custom-multiselect.component.html',
  styleUrl: './custom-multiselect.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomMultiselectComponent<T> {
  public options = input.required<T[]>();
  public prefill = input<T[]>([]);
  public placeholder = input<string>();

  public changeEvent = output<T[]>();

  public selected = signal<T[]>([]);
  public hasSearch = computed(
    () => this.options().length > CUSTOM_MULTISELECT_SEARCH_BAR_LIMIT,
  );
  public triState = computed(() =>
    this.selected().length !== 0
      ? this.options().length === this.selected().length
      : null,
  );
  public scrollHeight = `${CUSTOM_MULTISELECT_SCROLL_BAR_LIMIT * CUSTOM_MULTISELECT_OPTION_HEIGHT}px`;

  constructor() {
    effect(() => {
      this.selected.set(this.prefill());
    });
  }

  onChange(event: MultiSelectChangeEvent): void {
    this.selected.set(event.value);
    this.changeEvent.emit(this.selected());
  }

  onChangeTriState(event: TriStateCheckboxChangeEvent): void {
    event.originalEvent.stopImmediatePropagation();
    this.selected.set(event.value ? this.options() : []);
    this.changeEvent.emit(this.selected());
  }
}
