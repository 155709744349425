import { Route } from '@angular/router';

import { authGuard } from '@customer-portal/shared';

export const appRoutes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/overview',
  },
  {
    path: 'overview',
    loadChildren: () =>
      import('@customer-portal/features/overview').then(
        (r) => r.OVERVIEW_ROUTES,
      ),
    title: 'Overview',
    canActivate: [authGuard],
  },
  {
    path: 'apps',
    loadChildren: () =>
      import('@customer-portal/features/external-apps').then(
        (r) => r.EXTERNAL_APPS_ROUTES,
      ),
    data: {
      breadcrumb: {
        i18nKey: 'apps',
      },
    },
    title: 'External Apps',
    canActivate: [authGuard],
  },
  {
    path: 'audits',
    loadChildren: () =>
      import('@customer-portal/features/audit').then((r) => r.AUDIT_ROUTES),
    data: {
      breadcrumb: {
        i18nKey: 'audits',
      },
    },
    title: 'Audits',
    canActivate: [authGuard],
  },
  {
    path: 'certificates',
    loadChildren: () =>
      import('@customer-portal/features/certificates').then(
        (r) => r.CERTIFICATES_ROUTES,
      ),
    data: {
      breadcrumb: {
        i18nKey: 'certificates',
      },
    },
    title: 'Certificates',
    canActivate: [authGuard],
  },
  {
    path: 'contracts',
    loadChildren: () =>
      import('@customer-portal/features/contracts').then(
        (r) => r.CONTRACTS_ROUTES,
      ),
    data: {
      breadcrumb: {
        i18nKey: 'contracts',
      },
    },
    title: 'Contracts',
    canActivate: [authGuard],
  },
  {
    path: 'financials',
    loadChildren: () =>
      import('@customer-portal/features/financials').then(
        (r) => r.FINANCIALS_ROUTES,
      ),
    data: {
      breadcrumb: {
        i18nKey: 'financials',
      },
    },
    title: 'Financials',
    canActivate: [authGuard],
  },
  {
    path: 'findings',
    loadChildren: () =>
      import('@customer-portal/features/findings').then(
        (r) => r.FINDINGS_ROUTES,
      ),
    data: {
      breadcrumb: {
        i18nKey: 'findings',
      },
    },
    title: 'Findings',
    canActivate: [authGuard],
  },
  {
    path: 'schedule',
    loadChildren: () =>
      import('@customer-portal/features/schedules').then(
        (r) => r.SCHEDULES_ROUTES,
      ),
    data: {
      breadcrumb: {
        i18nKey: 'schedule',
      },
    },
    title: 'Schedule',
    canActivate: [authGuard],
  },
  {
    path: 'actions',
    loadChildren: () =>
      import('@customer-portal/features/actions').then((r) => r.ACTIONS_ROUTES),
    data: {
      breadcrumb: {
        i18nKey: 'actions',
      },
    },
    title: 'Actions',
    canActivate: [authGuard],
  },
  {
    path: 'notifications',
    loadChildren: () =>
      import('@customer-portal/features/notifications').then(
        (r) => r.NOTIFICATIONS_ROUTES,
      ),
    data: {
      breadcrumb: {
        i18nKey: 'notifications',
      },
    },
    title: 'Notifications',
    canActivate: [authGuard],
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('@customer-portal/features/settings').then(
        (r) => r.SETTINGS_ROUTES,
      ),
    data: {
      breadcrumb: {
        i18nKey: 'settings',
      },
    },
    title: 'Settings',
    canActivate: [authGuard],
  },
  {
    path: 'welcome',
    loadChildren: () =>
      import('@customer-portal/features/welcome').then((r) => r.WELCOME_ROUTES),
    title: 'Welcome',
  },
];
