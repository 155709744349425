export const SHARED_SELECT_MULTIPLE_OPTION_TOOLTIP_DEFAULT_DELAY_MS = 100;
export const SHARED_SELECT_MULTIPLE_OPTION_TOOLTIP_LARGE_DELAY_MS = 100000;
export const SHARED_SELECT_MULTIPLE_OPTION_TOOLTIP_LIMIT = 0;
export const SHARED_SELECT_MULTIPLE_OPTION_HEIGHT_PX = 40;
export const SHARED_SELECT_MULTIPLE_SCROLL_BAR_LIMIT = 8;
export const SHARED_SELECT_MULTIPLE_SCROLL_PADDING_PX = 16;
export const SHARED_SELECT_MULTIPLE_SEARCH_LIMIT = 15;
export const SHARED_SELECT_MULTIPLE_SCROLL_HEIGHT_PX =
  SHARED_SELECT_MULTIPLE_OPTION_HEIGHT_PX *
    SHARED_SELECT_MULTIPLE_SCROLL_BAR_LIMIT +
  SHARED_SELECT_MULTIPLE_SCROLL_PADDING_PX;
