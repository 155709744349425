@if(isLoggedIn) {
<customer-portal-navbar (toggleSidebarEvent)="sidebarMobile.onToggle($event)"></customer-portal-navbar>
<customer-portal-sidebar></customer-portal-sidebar>
}
<section>
  @if(isLoggedIn) {
  <customer-portal-breadcrumb></customer-portal-breadcrumb>
  }
  <div class="content">
    <lib-spinner></lib-spinner>
    <router-outlet></router-outlet>
  </div>
  <customer-portal-sidebar-mobile #sidebarMobile></customer-portal-sidebar-mobile>
</section>

<shared-custom-confirm-dialog></shared-custom-confirm-dialog>
<shared-custom-toast></shared-custom-toast>