<ng-container *transloco="let t">
  <p-card (click)="onCardClick()">
    <ng-template pTemplate="header">
      @if (imageUrl) {
        <img alt="card" [src]="imageUrl" />
      }
      <div class="container">
        <h6>
          <span>{{ title }}</span>
          @if (link) {
            <a>
              <i class="pi pi-external-link"></i>
              <span class="sr-only">{{ t('linkTo', { title }) }}</span>
            </a>
          }
        </h6>
        <p>{{ subTitle }}</p>
      </div>
    </ng-template>
  </p-card>
</ng-container>
