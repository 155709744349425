import { Injectable, Signal } from '@angular/core';
import { Store } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Observable } from 'rxjs';

import {
  FilteringConfig,
  FilterOptions,
  GridConfig,
} from '@customer-portal/shared';

import {
  SettingsCompanyDetailsData,
  SettingsCompanyDetailsEditParams,
} from '../../models';
import {
  LoadSettingsCompanyDetails,
  ResetSettingsCompanyDetailsState,
  UpdateSettingsCompanyDetails,
  UpdateSettingsCompanyDetailsEntityListFilterOptions,
  UpdateSettingsCompanyDetailsEntityListGridConfig,
} from '../actions';
import { SettingsSelectors } from '../selectors';

@Injectable({ providedIn: 'root' })
export class SettingsCompanyDetailsStoreService {
  constructor(private store: Store) {}

  get isUserAdmin(): Signal<boolean> {
    return this.store.selectSignal(SettingsSelectors.isUserAdmin);
  }

  get legalEntityList(): Signal<SettingsCompanyDetailsData[]> {
    return this.store.selectSignal(SettingsSelectors.legalEntityList);
  }

  get totalLegalEntityList(): Signal<number> {
    return this.store.selectSignal(SettingsSelectors.totalLegalEntityList);
  }

  get parentCompany(): Signal<SettingsCompanyDetailsData | null> {
    return this.store.selectSignal(SettingsSelectors.parentCompany);
  }

  get legalEntityFilterOptions(): Signal<FilterOptions> {
    return this.store.selectSignal(SettingsSelectors.legalEntityFilterOptions);
  }

  get legalEntityFilteringConfig(): Observable<FilteringConfig> {
    return this.store.select(SettingsSelectors.legalEntityFilteringConfig);
  }

  get hasLegalEntityActiveFilters(): Signal<boolean> {
    return this.store.selectSignal(
      SettingsSelectors.hasLegalEntityActiveFilters,
    );
  }

  get legalEntityListCounter(): Signal<number> {
    return this.store.selectSignal(SettingsSelectors.legalEntityListCounter);
  }

  @Dispatch()
  loadSettingsCompanyDetails = () => new LoadSettingsCompanyDetails();

  @Dispatch()
  updateSettingsCompanyDetails = (params: SettingsCompanyDetailsEditParams) =>
    new UpdateSettingsCompanyDetails(params);

  @Dispatch()
  updateSettingsCompanyDetailsEntityListFilterOptions = () =>
    new UpdateSettingsCompanyDetailsEntityListFilterOptions();

  @Dispatch()
  updateSettingsCompanyDetailsEntityListGridConfig = (gridConfig: GridConfig) =>
    new UpdateSettingsCompanyDetailsEntityListGridConfig(gridConfig);

  @Dispatch()
  resetSettingsCompanyDetailsState = () =>
    new ResetSettingsCompanyDetailsState();
}
