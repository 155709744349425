import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { Action, State, StateContext } from '@ngxs/store';
import { TreeNode } from 'primeng/api';
import { filter, map, Observable, tap } from 'rxjs';

import {
  DEFAULT_GRID_CONFIG,
  FilterableColumnDefinition,
  FilterOptions,
  getFilterOptions,
  GridConfig,
  Language,
  LocaleService,
  PROFILE_EMAIL,
} from '@customer-portal/shared';

import {
  ProfileLanguageDto,
  SettingsAdminListDto,
  SettingsCompanyDetailsDataDto,
  SettingsCompanyDetailsDto,
  SettingsMembersListDto,
  SettingsMembersPermissionsDto,
  SettingsMembersRolesDto,
} from '../dtos';
import {
  ProfileInformationModel,
  ProfileSettingsSelection,
  SettingsCompanyDetailsData,
  SettingsMembersItemModel,
  SettingsNewMemberFormModel,
} from '../models';
import {
  ProfileLanguageMapperService,
  ProfileLanguageService,
  ProfileMapperService,
  ProfileService,
  SettingsAdminListService,
  SettingsCompanyDetailsMapperService,
  SettingsCompanyDetailsService,
  SettingsMembersListService,
  SettingsMembersMapper,
  SettingsMembersPermissionsService,
  SettingsMembersRolesService,
} from '../services';
import {
  LoadMemberRoles,
  LoadMemberRolesSuccess,
  LoadMembersPerimissionsServices,
  LoadMembersPermissions,
  LoadMembersPermissionsSites,
  LoadProfileData,
  LoadProfileLanguage,
  LoadProfileLanguageSuccess,
  LoadSettingsAdminList,
  LoadSettingsAdminListSuccess,
  LoadSettingsCompanyDetails,
  LoadSettingsCompanyDetailsSuccess,
  LoadSettingsMembersList,
  LoadSettingsMembersListSuccess,
  ResetAdminListState,
  ResetMembersListState,
  ResetSettingsCompanyDetailsState,
  UpdateAdminFilterOptions,
  UpdateAdminGridConfig,
  UpdateContinueToPermissionsStatus,
  UpdateMembersFilterOptions,
  UpdateMembersGridConfig,
  UpdateNewMemberForm,
  UpdateProfileLanguage,
  UpdateProfileLanguageSuccess,
  UpdateSettingsCompanyDetails,
  UpdateSettingsCompanyDetailsEntityListFilterOptions,
  UpdateSettingsCompanyDetailsEntityListGridConfig,
  UpdateSubmitSettingsStateValues,
  UpdateSubmitSettingsStatus,
  UpdateSubmitSettingsValues,
} from './actions';

export interface SettingsStateModel {
  isUserAdmin: boolean;
  parentCompany: SettingsCompanyDetailsData | null;
  legalEntityList: SettingsCompanyDetailsData[];
  legalEntityListCounter: number;
  legalEntityGridConfig: GridConfig;
  legalEntityFilterOptions: FilterOptions;
  adminList: SettingsMembersItemModel[];
  adminGridConfig: GridConfig;
  adminFilterOptions: FilterOptions;
  membersList: SettingsMembersItemModel[];
  membersGridConfig: GridConfig;
  membersFilterOptions: FilterOptions;
  information: ProfileInformationModel;
  userSelection: ProfileSettingsSelection;
  submitSettingsStatus: boolean;
  languageLabel: Language;
  memberRoles: string[];
  isAddMemberFormValid: boolean;
  newMemberForm: SettingsNewMemberFormModel | null;
  sites: TreeNode[];
  services: TreeNode[];
}

const defaultState: SettingsStateModel = {
  isUserAdmin: false,
  parentCompany: null,
  legalEntityList: [],
  legalEntityListCounter: 0,
  legalEntityGridConfig: DEFAULT_GRID_CONFIG,
  legalEntityFilterOptions: {},
  adminList: [],
  adminGridConfig: DEFAULT_GRID_CONFIG,
  adminFilterOptions: {},
  membersList: [],
  membersGridConfig: DEFAULT_GRID_CONFIG,
  membersFilterOptions: {},
  information: {
    firstName: '',
    lastName: '',
    displayName: '',
    country: '',
    region: '',
    email: '',
    phone: '',
    portalLanguage: '',
    communicationLanguage: 'English',
    jobTitle: '',
    languages: [],
  },
  userSelection: { communicationLanguage: 'English', jobTitle: '' },
  submitSettingsStatus: false,
  languageLabel: Language.English,
  memberRoles: [],
  isAddMemberFormValid: false,
  newMemberForm: null,
  sites: [],
  services: [],
};

const COLUMNS_DEFINITION: FilterableColumnDefinition[] = [
  { field: 'name', hasColumnDelimiter: false },
  { field: 'email', hasColumnDelimiter: false },
  { field: 'company', hasColumnDelimiter: true },
  { field: 'services', hasColumnDelimiter: true },
  { field: 'sites', hasColumnDelimiter: true },
  { field: 'access', hasColumnDelimiter: false },
];

const COMPANY_DETAILS_LEGAL_ENTITIES_COLUMNS_DEFINITION: FilterableColumnDefinition[] =
  [{ field: 'organizationName', hasColumnDelimiter: false }];

@State<SettingsStateModel>({
  name: 'settings',
  defaults: defaultState,
})
@Injectable()
export class SettingsState {
  constructor(
    private readonly settingsCompanyDetailsService: SettingsCompanyDetailsService,
    private readonly settingsMembersListService: SettingsMembersListService,
    private readonly settingsAdminListService: SettingsAdminListService,
    private readonly localeService: LocaleService,
    private profileService: ProfileService,
    private readonly profileLanguageService: ProfileLanguageService,
    private readonly translocoService: TranslocoService,
    private readonly settingsMembersRolesService: SettingsMembersRolesService,
    private readonly settingsMembersPermissionsService: SettingsMembersPermissionsService,
  ) {}

  @Action(LoadSettingsAdminList)
  loadSettingsAdminList(ctx: StateContext<SettingsStateModel>) {
    const userEmail = ctx.getState().information.email;

    return this.settingsAdminListService.getSettingsAdminList(userEmail).pipe(
      tap((data: SettingsAdminListDto) => {
        if (data.isSuccess) {
          const adminList = SettingsMembersMapper.mapToAdminList(data);
          ctx.dispatch(new LoadSettingsAdminListSuccess(adminList));
          ctx.dispatch(new UpdateAdminFilterOptions());
        }
      }),
    );
  }

  @Action(LoadSettingsAdminListSuccess)
  loadSettingsAdminListSuccess(
    ctx: StateContext<SettingsStateModel>,
    { adminList }: LoadSettingsAdminListSuccess,
  ) {
    ctx.patchState({
      adminList,
    });
  }

  @Action(LoadSettingsMembersList)
  loadSettingsMembersList(ctx: StateContext<SettingsStateModel>) {
    const userEmail = ctx.getState().information.email;

    return this.settingsMembersListService
      .getSettingsMembersList(userEmail)
      .pipe(
        tap((data: SettingsMembersListDto) => {
          if (data.isSuccess) {
            const membersList = SettingsMembersMapper.mapToMembersList(data);
            ctx.dispatch(new LoadSettingsMembersListSuccess(membersList));
            ctx.dispatch(new UpdateMembersFilterOptions());
          }
        }),
      );
  }

  @Action(LoadSettingsMembersListSuccess)
  loadSettingsMembersListSuccess(
    ctx: StateContext<SettingsStateModel>,
    { membersList }: LoadSettingsMembersListSuccess,
  ) {
    ctx.patchState({
      membersList,
    });
  }

  @Action(UpdateMembersGridConfig)
  updateMembersGridConfig(
    ctx: StateContext<SettingsStateModel>,
    { membersGridConfig }: UpdateMembersGridConfig,
  ): void {
    ctx.patchState({ membersGridConfig });
    ctx.dispatch(new UpdateMembersFilterOptions());
  }

  @Action(UpdateMembersFilterOptions)
  updateMembersFilterOptions(ctx: StateContext<SettingsStateModel>): void {
    const { membersGridConfig, membersList } = ctx.getState();

    const columnFilterConfigs: FilterableColumnDefinition[] =
      COLUMNS_DEFINITION;

    const filterOptions = getFilterOptions(
      membersList,
      membersGridConfig,
      columnFilterConfigs,
    );

    ctx.patchState({ membersFilterOptions: filterOptions });
  }

  @Action(ResetMembersListState)
  resetMembersListState(ctx: StateContext<SettingsStateModel>): void {
    ctx.patchState({
      adminList: [],
      adminGridConfig: DEFAULT_GRID_CONFIG,
      adminFilterOptions: {},
    });
  }

  @Action(UpdateAdminGridConfig)
  updateAdminGridConfig(
    ctx: StateContext<SettingsStateModel>,
    { adminGridConfig }: UpdateAdminGridConfig,
  ): void {
    ctx.patchState({ adminGridConfig });
    ctx.dispatch(new UpdateAdminFilterOptions());
  }

  @Action(UpdateAdminFilterOptions)
  updateAdminFilterOptions(ctx: StateContext<SettingsStateModel>): void {
    const { adminGridConfig, adminList } = ctx.getState();

    const columnFilterConfigs: FilterableColumnDefinition[] =
      COLUMNS_DEFINITION;

    const filterOptions = getFilterOptions(
      adminList,
      adminGridConfig,
      columnFilterConfigs,
    );

    ctx.patchState({ adminFilterOptions: filterOptions });
  }

  @Action(ResetAdminListState)
  resetAdminListState(ctx: StateContext<SettingsStateModel>): void {
    ctx.patchState({
      adminList: [],
      adminGridConfig: DEFAULT_GRID_CONFIG,
      adminFilterOptions: {},
    });
  }

  @Action(LoadMemberRoles)
  LoadMemberRoles(ctx: StateContext<SettingsStateModel>) {
    return this.settingsMembersRolesService.getSettingsMembersRoles().pipe(
      tap((data: SettingsMembersRolesDto) => {
        if (data.isSuccess) {
          ctx.dispatch(new LoadMemberRolesSuccess(data.data));
        }
      }),
    );
  }

  @Action(LoadMemberRolesSuccess)
  LoadMemberRolesSuccess(
    ctx: StateContext<SettingsStateModel>,
    { roles }: LoadMemberRolesSuccess,
  ) {
    ctx.patchState({ memberRoles: roles });
  }

  @Action(UpdateContinueToPermissionsStatus)
  updateContinueToPermissionsStatus(
    ctx: StateContext<SettingsStateModel>,
    { isAddMemberFormValid }: UpdateContinueToPermissionsStatus,
  ) {
    ctx.patchState({
      isAddMemberFormValid,
    });
  }

  @Action(UpdateNewMemberForm)
  updateNewMemberForm(
    ctx: StateContext<SettingsStateModel>,
    { newMemberForm }: UpdateNewMemberForm,
  ) {
    ctx.patchState({
      newMemberForm,
    });
  }

  @Action(LoadMembersPermissions)
  loadMembersPermissions(
    ctx: StateContext<SettingsStateModel>,
    { memberEmail, userEmail }: LoadMembersPermissions,
  ) {
    return this.settingsMembersPermissionsService
      .getSettingsMembersPermissions(memberEmail, userEmail)
      .pipe(
        tap((data: SettingsMembersPermissionsDto) => {
          if (data.isSuccess) {
            ctx.dispatch(
              new LoadMembersPerimissionsServices(
                SettingsMembersMapper.mapToMemberServices(data),
              ),
            );

            ctx.dispatch(
              new LoadMembersPermissionsSites(
                SettingsMembersMapper.mapToMemberSites(data),
              ),
            );
          }
        }),
      );
  }

  @Action(LoadMembersPerimissionsServices)
  loadMembersPerimissionsServices(
    ctx: StateContext<SettingsStateModel>,
    { services }: LoadMembersPerimissionsServices,
  ) {
    ctx.patchState({ services });
  }

  @Action(LoadMembersPermissionsSites)
  loadMembersPermissionsSites(
    ctx: StateContext<SettingsStateModel>,
    { sites }: LoadMembersPermissionsSites,
  ) {
    ctx.patchState({ sites });
  }

  /* Profile section */
  @Action(LoadProfileData)
  loadProfileData(ctx: StateContext<SettingsStateModel>) {
    return this.profileService.getProfileData(PROFILE_EMAIL).pipe(
      filter((profileDto) => profileDto.isSuccess),
      tap((profileDto) => {
        const profileData =
          ProfileMapperService.mapToProfileItemModel(profileDto);

        if (profileData?.information) {
          ctx.patchState({ information: profileData?.information });
        }
      }),
    );
  }

  @Action(UpdateSubmitSettingsStateValues)
  updateSubmitSettingsStateValues(
    ctx: StateContext<SettingsStateModel>,
    { submitSettingsValues }: UpdateSubmitSettingsStateValues,
  ) {
    const state = ctx.getState();

    const communicationLanguage =
      submitSettingsValues?.communicationLanguage?.languageName;
    const jobTitle = submitSettingsValues?.jobTitle;

    const userSelection: ProfileSettingsSelection = {
      ...state.userSelection,
      communicationLanguage,
      jobTitle,
    };

    ctx.patchState({ userSelection });
  }

  @Action(UpdateSubmitSettingsValues)
  updateSubmitSettingsValues(ctx: StateContext<SettingsStateModel>) {
    const state = ctx.getState();

    return this.profileService
      .updateProfileSettingsData(
        state.information.email,
        state.userSelection?.communicationLanguage,
        state.userSelection?.jobTitle,
      )
      .pipe(
        tap((res: any) => {
          if (res?.data?.updateProfile?.isSuccess) {
            const updatedProfile = res.data.updateProfile.data;
            const { communicationLanguage, jobTitle } = updatedProfile;

            const languages = state.information.languages.map(
              (language: any) => ({
                languageName: language.languageName,
                isSelected: language.languageName === communicationLanguage,
              }),
            );

            const profileInformation: ProfileInformationModel = {
              ...state.information,
              communicationLanguage,
              jobTitle,
              languages,
            };

            ctx.patchState({ information: profileInformation });
          }
        }),
      );
  }

  @Action(UpdateSubmitSettingsStatus)
  updateSubmitSettingsStatus(
    ctx: StateContext<SettingsStateModel>,
    { submitSettingsStatus }: UpdateSubmitSettingsStatus,
  ) {
    ctx.patchState({
      submitSettingsStatus,
    });
  }

  @Action(LoadProfileLanguage)
  loadProfileLanguage(ctx: StateContext<SettingsStateModel>) {
    return this.getProfileLanguage().pipe(
      tap((data) => {
        ctx.dispatch(
          new LoadProfileLanguageSuccess(
            ProfileLanguageMapperService.mapToProfileLanguageModel(data),
          ),
        );
      }),
    );
  }

  @Action(LoadProfileLanguageSuccess)
  loadProfileLanguageSuccess(
    ctx: StateContext<SettingsStateModel>,
    { languageLabel }: LoadProfileLanguageSuccess,
  ) {
    this.localeService.setLocale(languageLabel);
    this.translocoService.setActiveLang(languageLabel);
    ctx.patchState({ languageLabel });
  }

  @Action(UpdateProfileLanguage)
  updateProfileLanguage(
    ctx: StateContext<SettingsStateModel>,
    { languageLabel }: UpdateProfileLanguage,
  ) {
    return this.profileLanguageService
      .updateProfileLanguage(PROFILE_EMAIL, languageLabel)
      .pipe(
        tap((dto: ProfileLanguageDto) => {
          ctx.dispatch(
            new UpdateProfileLanguageSuccess(
              ProfileLanguageMapperService.mapToProfileLanguageModel(dto?.data),
            ),
          );
        }),
      );
  }

  @Action(UpdateProfileLanguageSuccess)
  updateProfileLanguageSuccess(
    ctx: StateContext<SettingsStateModel>,
    { languageLabel }: UpdateProfileLanguageSuccess,
  ) {
    this.localeService.setLocale(languageLabel);
    ctx.patchState({ languageLabel });
  }
  /* End of profile section */

  // #region SettingsCompanyDetails

  @Action(LoadSettingsCompanyDetails)
  loadSettingsCompanyDetails(ctx: StateContext<SettingsStateModel>) {
    return this.getSettingsCompanyDetails().pipe(
      tap((data) => {
        ctx.dispatch(
          new LoadSettingsCompanyDetailsSuccess(
            SettingsCompanyDetailsMapperService.mapToSettingsCompanyDetailsModel(
              data,
            ),
          ),
        );
        ctx.dispatch(new UpdateSettingsCompanyDetailsEntityListFilterOptions());
      }),
    );
  }

  @Action(LoadSettingsCompanyDetailsSuccess)
  loadSettingsCompanyDetailsSuccess(
    ctx: StateContext<SettingsStateModel>,
    { companyDetails }: LoadSettingsCompanyDetailsSuccess,
  ) {
    const { isUserAdmin, legalEntityList, parentCompany } = companyDetails;

    ctx.patchState({
      isUserAdmin,
      legalEntityList,
      legalEntityListCounter: legalEntityList.length,
      parentCompany,
    });
  }

  @Action(UpdateSettingsCompanyDetails)
  updateSettingsCompanyDetails(
    ctx: StateContext<SettingsStateModel>,
    { params }: UpdateSettingsCompanyDetails,
  ) {
    return this.editSettingsCompanyDetails(params);
  }

  @Action(UpdateSettingsCompanyDetailsEntityListFilterOptions)
  updateSettingsCompanyDetailsEntityListFilterOptions(
    ctx: StateContext<SettingsStateModel>,
  ) {
    const { legalEntityList, legalEntityGridConfig } = ctx.getState();

    const legalEntityFilterOptions = getFilterOptions(
      legalEntityList,
      legalEntityGridConfig,
      COMPANY_DETAILS_LEGAL_ENTITIES_COLUMNS_DEFINITION,
    );

    ctx.patchState({ legalEntityFilterOptions });
  }

  @Action(UpdateSettingsCompanyDetailsEntityListGridConfig)
  updateSettingsCompanyDetailsEntityListGridConfig(
    ctx: StateContext<SettingsStateModel>,
    { legalEntityGridConfig }: UpdateSettingsCompanyDetailsEntityListGridConfig,
  ) {
    ctx.patchState({ legalEntityGridConfig });
    ctx.dispatch(new UpdateSettingsCompanyDetailsEntityListFilterOptions());
  }

  @Action(ResetSettingsCompanyDetailsState)
  resetSettingsCompanyDetailsState(
    ctx: StateContext<SettingsStateModel>,
  ): void {
    ctx.patchState({
      legalEntityList: [],
      legalEntityGridConfig: DEFAULT_GRID_CONFIG,
      legalEntityFilterOptions: {},
    });
  }

  // #endregion SettingsCompanyDetails

  private getSettingsCompanyDetails(
    email = PROFILE_EMAIL,
  ): Observable<SettingsCompanyDetailsDataDto> {
    return this.settingsCompanyDetailsService
      .getSettingsCompanyDetails(email)
      .pipe(
        map((dto: SettingsCompanyDetailsDto) =>
          dto?.isSuccess && dto?.data
            ? dto.data
            : ({} as SettingsCompanyDetailsDataDto),
        ),
      );
  }

  private editSettingsCompanyDetails(params: any) {
    return this.settingsCompanyDetailsService.editSettingsCompanyDetails({
      email: PROFILE_EMAIL, // TODO: we may need to remove this at a later date, once API has matured
      ...params,
    });
  }

  private getProfileLanguage(email = PROFILE_EMAIL) {
    return this.profileLanguageService
      .getProfileLanguage(email)
      .pipe(
        map((dto: ProfileLanguageDto) =>
          dto?.isSuccess && dto?.data
            ? dto.data
            : { portalLanguage: Language.English },
        ),
      );
  }
}
