<ng-container *transloco="let t">
  <p-listbox
    [checkbox]="true"
    [disabled]="showNoValue"
    [filter]="filterFlag"
    [listStyle]="{ 'max-height': maxHeight + 'px' }"
    [multiple]="multiple"
    [optionLabel]="optionLabel"
    [options]="options"
    [showToggleAll]="false"
    [style]="{ width: width + 'px' }"
    [styleClass]="filterFlag ? 'filter-header' : ''"
    [virtualScroll]="true"
    [virtualScrollItemSize]="50"
    [(ngModel)]="selectedItems"
    (onChange)="onChangeOption($event)">
    <ng-template pTemplate="header">
      <div class="checkbox-filter-header">
        <p-triStateCheckbox
          [checkboxFalseIcon]="'pi pi-minus'"
          [label]="t('all')"
          [(ngModel)]="selectAll"
          (onChange)="onChangeSelectAll($event)"></p-triStateCheckbox>

        <p-checkbox
          class="override-disabled"
          [binary]="true"
          [disabled]="false"
          [label]="t('blank')"
          [(ngModel)]="showNoValue"
          (onChange)="onChangeEmptyValue()"></p-checkbox>
      </div>
    </ng-template>
  </p-listbox>
</ng-container>
