import { Selector } from '@ngxs/store';

import {
  applyGridConfig,
  FilteringConfig,
  FilterOptions,
  getNumberOfFilteredRecords,
  isAnyFilterActive,
} from '@customer-portal/shared';

import { SettingsCompanyDetailsData } from '../../models';
import { SettingsState, SettingsStateModel } from '../settings.state';

export class SettingsSelectors {
  @Selector([SettingsSelectors._isUserAdmin])
  static isUserAdmin(isUserAdmin: boolean): boolean {
    return isUserAdmin;
  }

  @Selector([SettingsSelectors._legalEntityList])
  static legalEntityList(
    legalEntityList: SettingsCompanyDetailsData[],
  ): SettingsCompanyDetailsData[] {
    return legalEntityList;
  }

  @Selector([SettingsSelectors._totalLegalEntityList])
  static totalLegalEntityList(totalLegalEntityList: number): number {
    return totalLegalEntityList;
  }

  @Selector([SettingsSelectors._parentCompany])
  static parentCompany(
    parentCompany: SettingsCompanyDetailsData | null,
  ): SettingsCompanyDetailsData | null {
    return parentCompany;
  }

  @Selector([SettingsSelectors._legalEntityFilterOptions])
  static legalEntityFilterOptions(
    legalEntityFilterOptions: FilterOptions,
  ): FilterOptions {
    return legalEntityFilterOptions;
  }

  @Selector([SettingsSelectors._legalEntityFilteringConfig])
  static legalEntityFilteringConfig(
    legalEntityFilteringConfig: FilteringConfig,
  ): FilteringConfig {
    return legalEntityFilteringConfig;
  }

  @Selector([SettingsSelectors._hasLegalEntityActiveFilters])
  static hasLegalEntityActiveFilters(
    hasLegalEntityActiveFilters: boolean,
  ): boolean {
    return hasLegalEntityActiveFilters;
  }

  @Selector([SettingsSelectors._legalEntityListCounter])
  static legalEntityListCounter(legalEntityListCounter: number): number {
    return legalEntityListCounter;
  }

  @Selector([SettingsState])
  private static _isUserAdmin(state: SettingsStateModel): boolean {
    return state.isUserAdmin;
  }

  @Selector([SettingsState])
  private static _legalEntityList(
    state: SettingsStateModel,
  ): SettingsCompanyDetailsData[] {
    const { legalEntityList, legalEntityGridConfig } = state;

    return applyGridConfig(legalEntityList, legalEntityGridConfig);
  }

  @Selector([SettingsState])
  private static _totalLegalEntityList(state: SettingsStateModel): number {
    const { legalEntityList, legalEntityGridConfig } = state;

    return getNumberOfFilteredRecords(legalEntityList, legalEntityGridConfig);
  }

  @Selector([SettingsState])
  private static _parentCompany(
    state: SettingsStateModel,
  ): SettingsCompanyDetailsData | null {
    return state?.parentCompany;
  }

  @Selector([SettingsState])
  private static _legalEntityFilterOptions(
    state: SettingsStateModel,
  ): FilterOptions {
    return state.legalEntityFilterOptions;
  }

  @Selector([SettingsState])
  private static _legalEntityFilteringConfig(
    state: SettingsStateModel,
  ): FilteringConfig {
    return state.legalEntityGridConfig.filtering;
  }

  @Selector([SettingsState])
  private static _hasLegalEntityActiveFilters(
    state: SettingsStateModel,
  ): boolean {
    return isAnyFilterActive(state.legalEntityGridConfig.filtering);
  }

  @Selector([SettingsState])
  private static _legalEntityListCounter(state: SettingsStateModel): number {
    return state?.legalEntityListCounter;
  }
}
