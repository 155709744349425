<ng-container *transloco="let t">
  <div
    class="chart-legend"
    [id]="legendId"
    [style.display]="hasData && useCustomLegend ? 'block' : 'none'"></div>

  <ng-content select="[header]"></ng-content>

  <canvas #chartCanvas [style.display]="hasData ? 'block' : 'none'"></canvas>

  @if (!hasData) {
    <div
      class="no-data"
      [ngClass]="{
        'no-data-border': hasBorderIfNoData,
        'no-data-bar': type === 'bar',
      }">
      <p>{{ t('noDataAvailable') }}</p>
    </div>
  }
</ng-container>
